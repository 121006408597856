import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

//Localization Imports
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { useGenFocCalculation } from "../../../navigationPage/hooks/useGenFocCalculation.js";
import localizationKeys from "../../../../i18n/localizationKeys.js";
import { fetchTrimAndHeelLatestData } from "../../../../api/latestSepData.js";
import { Grid } from "@mui/material";
import { COLORS } from "../../../../constants/colors.js";
import { DEFAULT_DASH_VALUE } from "../../../../constants/constants.js";
import { useDispatch } from "react-redux";
import { FOC_DURATION_LIST, setSelectedFOCDuration } from "../../../../model/slice/fleetNavigationDataSlice.js";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100% !important",
  },
  titleContainer: {
    textAlign: "center",
    fontSize: "11px",
    color: COLORS.greenishBlue,
    fontWeight: "500",
  },
  sepDataHolder: {
    width: "100%",
  },
  iconLabel: {
    position: "absolute",
    top: "25px",
    left: "26px",
    fontSize: "13px",
  },
  iconLabelSelected: {
    color: "#ffffff",
  },
  iconHolder: {
    cursor: "pointer",
    position: "relative",
  },
  geFailureMsg:{
    color: "#ffffff",
    fontSize: "13px",
  },
  navValueTextSize: {
    fontSize: "12px",
    textAlign: "right",
  },
  label: {
    color: COLORS.greenishBlue,
  },
  navLabelTextSize: {
    fontSize: "12px",
  },
}));

const SepData = (props) => {
  const dispatch = useDispatch();
  const { vessel, t } = props;
  const styles = useStyles();
  const classes = { ...props.classes, ...styles };
  const [geFoc, setGeFoc] = React.useState();
  const [geFailure, setGeFailure] = React.useState();
  const [trim, setTrim] = React.useState(DEFAULT_DASH_VALUE);
  const [heel, setHeel] = React.useState(DEFAULT_DASH_VALUE);

  const { currentFoc } = useGenFocCalculation({
    vesselId: vessel.id,
    flowmeterType: vessel?.generatorFoUnitFactor,
  });

  const focValue = (value) => !isNaN(value) ? (value * (vessel?.generatorFoUnit === 'l' ? 0.001 : 1)).toFixed(2) : "-";

  const focUnit = () => {
    const unit = vessel?.generatorFoUnit === 'l' ? 'kl' : vessel?.generatorFoUnit;
    return `${unit.toUpperCase()}/day`;
  };

  React.useEffect(async () => {
    dispatch(setSelectedFOCDuration(FOC_DURATION_LIST[0]));

    const data = await fetchTrimAndHeelLatestData(vessel.id);
    const sepData = data.latestTrimAndHeelData?.[0]?.data?.[0];
    if (sepData) {
      setTrim(sepData.trimValue?.toFixed(2) ?? DEFAULT_DASH_VALUE);
      setHeel(sepData.heelValue?.toFixed(2) ?? DEFAULT_DASH_VALUE);
    }

    return () => {
      setTrim(DEFAULT_DASH_VALUE);
      setHeel(DEFAULT_DASH_VALUE);
    }
  }, [vessel.id])

  React.useEffect(() => {
    if (currentFoc === undefined || currentFoc === null) {
      return;
    }
    setGeFoc(currentFoc.geTotal);
    setGeFailure(currentFoc.geFailure);
  }, [currentFoc]);

  return (
    <Grid container className={`${classes.container} ${classes.components}`} rowGap={2}>
      <Grid item xs={12} className={classes.titleContainer}>
        {t(localizationKeys.BasicSepData_upper)}
      </Grid>
      {(() => {
        if (geFailure?.length > 0) {
          return (
            <div className={clsx(classes.navValueRight, classes.geFailureMsg)}>
              *Generator # {geFailure.join(", # ")} flow counter(s) failed.
            </div>
          );
        }
      })()}
      <table className={classes.sepDataHolder}>
        <tbody>
          <tr>
            <td>
              <div className={`${classes.label} ${classes.navLabelTextSize}`}>
                {t(localizationKeys.TotalDieselEngineFoc_upper)}
              </div>
            </td>
            <td>
              {/* Generator Engine FOC */}
              <div
                data-testid="generator_engine_foc_data"
                className={clsx(classes.navValue, classes.navValueTextSize)}
              >
                {focValue(geFoc)} {focUnit()}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${classes.label} ${classes.navLabelTextSize}`}>
                {t(localizationKeys.Trim_upper)}
              </div>
            </td>
            <td>
              <div className={clsx(classes.navValue, classes.navValueTextSize)}>{trim} DEG</div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${classes.label} ${classes.navLabelTextSize}`}>
                {t(localizationKeys.Heel_upper)}
              </div>
            </td>
            <td>
              <div className={clsx(classes.navValue, classes.navValueTextSize)}>{heel} DEG</div>
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  );
};

SepData.propTypes = {
  vessel: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SepData);
