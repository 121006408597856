import { useEffect, useRef, useState } from "react";
import {
  fetchCraneData,
  fetchLegData,
  fetchThrusterData,
  fetchTrimAndHeelData,
} from "../api/sepTrendData";
import * as Comlink from "comlink";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getSepDataDuration } from "../model/slice/dataDurationSlice";
import { selectedChartType } from "../model/slice/chartTypeSlice";
import LegCalculateWorker from "../helpers/sepVessel/legCalculate.worker";
import { calDurations } from "../constants/calDuration.js";
import ThrusterCalculateWorker from "../helpers/sepVessel/thrusterCalculate.worker";
import { SEP_CHART_TYPE, crane1Graph } from "../constants/trendGraph/sepLines";
import { logger } from "../api/logger";
import { currentVesselSelector } from "../model/slice/vesselSlice";

export const AMOUNT_OF_CRANE = 1;
export const AMOUNT_OF_LEGS = 4;
export const AMOUNT_OF_THRUSTERS = 4;

const INITIAL_SEP_DATA = {
  [SEP_CHART_TYPE.CRANE1]: [],
  [SEP_CHART_TYPE.CRANE2]: [],
  [SEP_CHART_TYPE.LEGS]: [],
  [SEP_CHART_TYPE.THRUSTER]: [],
  foc: [],
  [SEP_CHART_TYPE.TRIM_AND_HEEL]: [],
};

// fetch leg data
export const fetchLeg = async (
  vesselId,
  startDate,
  endDate,
  interval,
  cancelToken
) => {
  const fetchLegs = await fetchLegData(vesselId, null, startDate, endDate, interval, cancelToken);
  return fetchLegs.legData;
};

// fetch sep data
export const fetchCrane = async (
  vesselId,
  startDate,
  endDate,
  interval,
  amountOfCrane = AMOUNT_OF_CRANE,
  cancelToken
) => {
  const fetchCranes = await fetchCraneData(vesselId, null, startDate, endDate, interval, cancelToken);
  // setCraneRawData(fetchCranes.map(d => d?.craneData).flat());
  return fetchCranes.craneData;
};

export const fetchThruster = async (
  vesselId,
  startDate,
  endDate,
  interval,
  amountOfThrusters = AMOUNT_OF_THRUSTERS,
  cancelToken
) => {
  const amountCount = !amountOfThrusters ? AMOUNT_OF_THRUSTERS : Number(amountOfThrusters);
  const thrusters = await Promise.all(
    [...new Array(amountCount)].map((d, i) =>
      fetchThrusterData(vesselId, startDate, endDate, i + 1, interval, cancelToken)
    )
  );
  return thrusters.map((d) => d?.thrusterData).flat();
};

export const fetchTrimHeel = async (vesselId, startDate, endDate, interval, cancelToken) => {
  const { trimAndHeelData } = await fetchTrimAndHeelData(
    vesselId,
    startDate,
    endDate,
    interval,
    cancelToken
  );
  // setTrimHeelRawData(trimAndHeelData);
  return trimAndHeelData;
};

export const legDataProcess = async (legRawData, unmount, setSepValues, NoLeg) => {
  const legWorker = LegCalculateWorker();
  const legWrap = Comlink.wrap(legWorker);
  const legTrendData = await legWrap?.generateTrendGraphData({ legRawData, NoLeg});
  unmount.current || setSepValues((d) => ({ ...d, [SEP_CHART_TYPE.LEGS]: legTrendData }));
  legWorker.terminate();
};

export const craneDataProcess = async (craneRawData, setSepValues) => {
  await new Promise((resolve, reject) => {
    try {
      if (!craneRawData) resolve();
      const returnData = filterCraneData(craneRawData, 0);
      setSepValues((d) => ({
        ...d,
        [SEP_CHART_TYPE.CRANE1]: returnData[0],
        [SEP_CHART_TYPE.CRANE2]: returnData[1],
      }));
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export const thrusterDataProcess = async (thrusterRawData, unmount, setSepValues, NoThruster) => {
  const thrusterWorker = ThrusterCalculateWorker();
  const thrusterWrap = Comlink.wrap(thrusterWorker);
  const thrusterTrendData = await thrusterWrap?.generateThrusterTrendGraphData({
    thrusterRawData,
    NoThruster
  });
  unmount.current ||
    setSepValues((d) => ({
      ...d,
      [SEP_CHART_TYPE.THRUSTER]: thrusterTrendData,
    }));
  thrusterWorker.terminate();
};

export const trimHeelProcess = async (trimHeelRawData, setSepValues) => {
  await new Promise((resolve, reject) => {
    const returnData = filterTrimHeelData(trimHeelRawData);
    setSepValues((d) => ({
      ...d,
      [SEP_CHART_TYPE.TRIM_AND_HEEL]: returnData,
    }));
    resolve();
  });
}

export const filterCraneData = (data, number) => {
  if (!data?.length) return [];
  const filteredData = data?.filter((d) => d?.craneNumber === 0) ?? [];
  const keyList = Object.keys(filteredData[0]);
  const crane1Data = [];
  const crane2Data = [];
  let crane1Element = {};
  let crane2Element = {};
  const excludeList = ["windSpeed", "windDirection", "slewMomentum", "craneOffLead"]; //for cp-8001, we need to concern how to implement individual vessel setting
  const crane1KeyList = crane1Graph.dataList.map((d) => d.key);
  for (let e of filteredData) {
    crane1Element = {};
    crane2Element = {};
    crane1Element["dateTime"] = e.dateTime;
    crane1Element["vessel_id"] = e.vessel_id;
    crane2Element["dateTime"] = e.dateTime;
    crane2Element["vessel_id"] = e.vessel_id;
    keyList.map((label) => {
      if (!excludeList.includes(label)) {
        crane1KeyList.includes(label)
          ? (crane1Element[label] = e[label])
          : (crane2Element[label] = e[label]);
      }
    });
    crane1Data.push(crane1Element);
    crane2Data.push(crane2Element);
  }
  switch (number) {
    case 0: //return both
      return [crane1Data, crane2Data];
    case 1:
      return crane1Data;
    case 2:
      return crane2Data;
  }
};

export const filterTrimHeelData = (data) => {
  data?.map((d) => {
    delete d.foreDraftP;
    delete d.foreDraftS;
    delete d.aftDraftP;
    delete d.aftDraftS;

    return d;
  });
  return data;
};

export const useSepTrendData = (vesselId) => {
  const currentVessel = useSelector(currentVesselSelector);
  const { startDate, endDate } = useSelector(getSepDataDuration);
  const chartType = useSelector(selectedChartType);
  const unmount = useRef(false);

  // current status
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  // trend graph data for each column
  const [sepValues, setSepValues] = useState(INITIAL_SEP_DATA);

  const handleFetchSuccess = (d) => {
    unmount.current || setIsLoading(false);
    unmount.current || setIsNoData(!d?.length);
  };

  const handleFetchError = (err) => {
    unmount.current || setIsLoading(false);
    unmount.current || setIsNoData(true);
    logger.error(`Fetch Error: ${err}`);
  };

  // initial load api data
  useEffect(() => {
    if (!Object.values(SEP_CHART_TYPE).includes(chartType)) return;
    setIsNoData(false);
    setIsLoading(true);
    if (!startDate || !endDate) return;
    logger.info("Fetch Data Started.");
    const calInterval = calDurations({ startDate, endDate });
    // for now, only sep vessel fetch 1 sec data for 1 day
    const interval = calInterval <= 1 ? 0 : calInterval;

    switch (chartType) {
      case SEP_CHART_TYPE.LEGS:
        fetchLeg(vesselId, startDate, endDate, interval)
          .then((d) => {
            legDataProcess(d, unmount, setSepValues, currentVessel?.NoLeg).then(() => handleFetchSuccess(d));
          })
          .catch(handleFetchError);
        break;
      case SEP_CHART_TYPE.THRUSTER:
        fetchThruster(vesselId, startDate, endDate, interval, currentVessel?.NoThruster)
          .then((d) => {
            thrusterDataProcess(d, unmount, setSepValues, currentVessel?.NoThruster).then(() => handleFetchSuccess(d));
          })
          .catch(handleFetchError);
        break;
      case SEP_CHART_TYPE.CRANE1:
      case SEP_CHART_TYPE.CRANE2:
        fetchCrane(vesselId, startDate, endDate, interval, currentVessel?.NoCrane)
          .then((d) => {
            craneDataProcess(d, setSepValues).then(() => handleFetchSuccess(d));
          })
          .catch(handleFetchError);
        break;
      case SEP_CHART_TYPE.TRIM_AND_HEEL:
        fetchTrimHeel(vesselId, startDate, endDate, interval)
          .then((d) => {
            trimHeelProcess(d, setSepValues).then(() => handleFetchSuccess(d));
          })
          .catch(handleFetchError);
        break;
      default:
        logger.error(`Invalid Chart Type: ${chartType}`);
    }
  }, [vesselId, chartType, startDate, endDate]);

  // for detect unmounted
  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    sepValues,
    isNoData,
    isLoading,
  };
};

useSepTrendData.propTypes = {
  vesselId: PropTypes.string,
  dataRange: PropTypes.object,
};
